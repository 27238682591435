<template>
  <tr class="line-item-tr" :data-id="pixel.id ? pixel.id : -1">
    <!-- only for All partners selected -->
    <td>
      {{ pixel.name }}
    </td>
    <td>
      {{ pixel.type }}
    </td>
    <td>
      {{ createdAt }}
    </td>
    <td>
      <button :id="buttonId" @click="copyToClip()" v-if="!copied">
        <font-awesome-icon
          :icon="['fal', 'code']"
          size="1x"
        ></font-awesome-icon>
      </button>
      <span v-else>Copied</span>
    </td>
  </tr>
</template>

<script>
import { PIXEL_JAVA_SCRIPT_BASE_URL } from "./../../constant";
import { isBlank } from "adready-api/helpers/common";
import moment from "moment";
export default {
  name: "PixelRow",

  props: {
    pixel: {
      required: false,
      type: Object,
      default: null,
    },

    idx: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    buttonId() {
      return "copyBtn" + this.pixel.id;
    },
    createdAt() {
      if (isBlank(this.pixel.createdAt)) {
        return "";
      }
      return moment(this.pixel.createdAt).format("YYYY-MM-DD h:mm:ssa");
    },
  },
  methods: {
    pixelScript() {
      let t = this.pixel.type || "CONVERSION";
      const pixelURL =
        PIXEL_JAVA_SCRIPT_BASE_URL +
        (t === "CONVERSION" ? "px?id=" : "seg?add=") +
        this.pixel.xandrPixelId +
        "&t=1"; // t=1 denotes js (script) tag

      // capitalize first letter of type
      t = t.toLowerCase();
      t = t[0].toUpperCase() + t.substring(1);

      return `<!-- ${t} pixel ${this.pixel.xandrPixelId} -->
        <script src='${pixelURL}' />
        <!-- End of ${t} pixel -->
        `;
    },
    copyToClip() {
      this.copied = true;
      window.navigator.clipboard.writeText(this.pixelScript());
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.line-item-tr {
  padding: 5px;
  border-bottom: 1px solid #e0e0e0;
  td {
    min-width: 183px;
    padding: 0px 5px !important;
    font-size: 13px !important;
    color: #74747d !important;
    text-align: center !important;
    vertical-align: top !important;
  }
}
</style>
