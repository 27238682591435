<template>
  <div class="pixel-data">
    <h3>Pixel Manager</h3>
    <table ref="lineItemsTable" class="pixel-table">
      <thead>
        <tr ref="pixelHeaderRow">
          <th
            v-for="(header, lkey) in headers"
            :key="lkey"
            :style="{ top: headerTop + 'px' }"
            :class="['table-header']"
            @mouseover="header.hover = true"
            @mouseleave="header.hover = false"
          >
            <span>{{ header.name }}</span>
          </th>
        </tr>
      </thead>

      <div v-if="loader" class="load-row">
        <svg class="spinner" width="60px" height="60px" viewBox="0 0 66 66">
          <circle
            class="path"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
      <div v-if="pixelsData">
        <pixel-row
          v-for="(p, idx) in pixels"
          :key="p._uuid"
          :pixel="p"
          :idx="idx"
        />
      </div>
      <div v-else>No Data Available</div>
    </table>
  </div>
</template>

<script>
import { isBlank } from "adready-api/helpers/common";
import PixelRow from "./pixel-row.vue";

export default {
  name: "PixelTable",

  components: {
    PixelRow,
  },

  props: {
    pixels: {
      require: true,
      type: Array,
      default: () => [],
    },
    loader: {
      require: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      onWindowScrollHeader: null,
      headerTop: 0,
      headers: [
        {
          name: "Pixel Name",
          defaultSortOrder: "asc",
          sortCol: "name",
          sortOrder: null,
          hover: false,
          show: true,
        },
        {
          name: "Type",
          defaultSortOrder: "asc",
          sortCol: "type",
          sortOrder: null,
          hover: false,
          show: true,
        },
        {
          name: "Date Created",
          show: true,
        },
        {
          name: "Pixel Code",
          defaultSortOrder: "asc",
          sortCol: "assignedTo",
          sortOrder: null,
          hover: false,
          show: true,
        },
      ],
    };
  },

  computed: {
    pixelsData() {
      return !isBlank(this.pixels);
    },
  },
};
</script>
<style lang="scss" scoped>
.pixel-data {
  width: 98%;
  margin: 20px auto;
  line-height: 45px;
  border-top: 1px solid #e0e0e0;
}
.pixel-table {
  display: inline-block;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  &.no-border {
    border-top: 0 !important;
  }

  thead {
    tr th {
      position: sticky;
      top: 0px;
      z-index: 3;
      width: 185px;
      background-color: #fdce07;
      transition: unset;
      span {
        font-size: 13px;
        color: #74747d;
        text-transform: uppercase;
      }
    }
  }
}

.load-row {
  min-height: unset !important;
  padding: 20px 0 !important;
  .spinner {
    // margin-top: 20% !important;
    margin-left: 50% !important;
  }
}
</style>
