<template>
  <div class="wrapper">
    <div class="row between-xs pad-sm">
      <button
        id="helpBtn"
        class="button solid col-xs btn-right"
        @click="showPixelHelpModal"
      >
        Help
      </button>
      <div class="col-xs btn-right close" @click="$emit('close')">
        <font-awesome-icon
          :icon="['fal', 'times']"
          size="2x"
        ></font-awesome-icon>
      </div>
    </div>

    <div v-if="pixel.id" class="responseMsg">
      <div class="row between-xs">
        <div class="col-xs">
          <h2>Pixels</h2>
        </div>
      </div>
      <div class="row between-xs pad-md-1">
        <div class="col-xs">
          <strong>Pixel Code:</strong>
        </div>
      </div>
      <div class="row middle-xs between-xs pad-sm">
        <div class="col-xs">
          <div>
            Place these tags just before the &lt;/head&gt; tag on your web page:
            <pre><code class="language-html" id="scriptag">{{
              pixelScript
            }}</code></pre>
          </div>
        </div>
      </div>
      <div class="row middle-xs between-xs pad-sm">
        <div class="col-xs-3">
          <button id="copyBtn" class="button solid" @click="copyToClip()">
            Copy Pixel Code
          </button>
        </div>
      </div>
      <div class="row middle-xs between-xs pad-sm">
        <div class="col-xs-12">
          <br />For help with pixel implementation, please review our
          <a class="a-inline" href="/help/pixel" target="_blank"
            >pixel implementation guide</a
          >.
        </div>
      </div>
    </div>

    <div v-else id="pixelForm">
      <div class="row between-xs">
        <div class="col-xs">
          <div v-if="loader" class="load-row">
            <svg class="spinner" width="60px" height="60px" viewBox="0 0 66 66">
              <circle
                class="path"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
      <div class="row between-xs">
        <div class="col-xs">
          <h2>Add Pixel</h2>
          <div v-if="error" class="error">{{ error }}</div>
        </div>
      </div>
      <div class="row middle-xs between-xs">
        <div class="col-xs">
          <div class="row">
            <div class="col-xs-6" v-if="isAccountManager">
              <label
                >Select Account:
                <v-select
                  :options="accounts"
                  label="name"
                  :value="account"
                  @input="onSelectAccount"
                ></v-select>
              </label>
            </div>
            <div class="col-xs-6">
              <label
                >Select Advertiser:
                <v-select
                  :options="advertisers"
                  label="name"
                  :value="advertiser"
                  @input="setSelected"
                ></v-select>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-xs">
              <label for=""
                >Enter Pixel Name
                <input type="text" name="Pixel Name" @input="setPixelName" />
              </label>
              <label class="sm-text"
                >Please select the type of tracking pixel you would like to
                create</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-xs">
              <button
                class="button solid"
                :class="{ disabled: loader }"
                @click="createPixel('CONVERSION')"
              >
                Create Conversion Pixel
              </button>
            </div>
            <div class="col-xs">
              <button
                class="button solid"
                :class="{ disabled: loader }"
                @click="createPixel('SEGMENT')"
              >
                Create Retargeting Pixel
              </button>
            </div>
          </div>
          <div>
            <pixel-table :pixels="pixelData" :loader="dataLoader" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css";

import { get } from "vuex-pathify";
import $ from "jquery";

import advertiserApi from "adready-api/api/advertiser";
import { isBlank } from "adready-api/helpers/common";

import pixelApi from "../api/pixel";
import {
  ADVERTISER_TYPE,
  PIXEL_JAVA_SCRIPT_BASE_URL,
  ADREADY_ACCOUNT_MANAGER_ROLE_ID,
} from "./../constant";

import pixelTable from "./pixel/pixel-table.vue";

const PixelHelpModal = () => import("@/components/PixelHelpModal");

export default {
  components: { pixelTable },

  data() {
    return {
      errorMsg: null,
      advertisers: [],
      pixel: {
        advertiserId: null,
        type: null,
        name: null,
        id: null,
        xandrPixelId: null,
      },
      loader: false,
      account: null,
      dataLoader: false,
      advertiser: null,
    };
  },

  computed: {
    adreadyAccount: get("adreadyAccount"),
    userAccounts: get("userAccounts"),
    authentication: get("authentication"),
    accounts() {
      return this.userAccounts.filter((ua) => !isBlank(ua.advisrGroupId));
    },
    isAccountManager() {
      if (this.authentication) {
        const userAccountRoles = this.authentication.userAccountRoles;
        if (userAccountRoles) {
          const userAccountRole = userAccountRoles.find(
            (uar) =>
              uar.userId == this.authentication.currentAdreadyUserId &&
              uar.accountId == this.adreadyAccount.id
          );
          if (
            userAccountRole &&
            userAccountRole.role.id === ADREADY_ACCOUNT_MANAGER_ROLE_ID
          ) {
            return true;
          }
        }
      }
      return false;
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.adreadyAccount) && !this.adreadyAccount.advisrGroupId) {
        return "No Advertiser is mapped to your account, please contact support";
      }
      return null;
    },
    accountAdvertisers() {
      if (this.account) {
        return this.account.advertisers;
      }
      return this.adreadyAccount.advertisers;
    },

    pixelScript() {
      let t = this.pixel.type || "CONVERSION";
      const pixelURL =
        PIXEL_JAVA_SCRIPT_BASE_URL +
        (t === "CONVERSION" ? "px?id=" : "seg?add=") +
        this.pixel.xandrPixelId +
        "&t=1"; // t=1 denotes js (script) tag

      // capitalize first letter of type
      t = t.toLowerCase();
      t = t[0].toUpperCase() + t.substring(1);

      return `<!-- ${t} pixel ${this.pixel.xandrPixelId} -->
        <script src='${pixelURL}' />
        <!-- End of ${t} pixel -->
        `;
    },
  },

  asyncComputed: {
    async pixelData() {
      let p = [];
      if (this.advertiser && this.advertiser.id) {
        this.dataLoader = true;
        p = await pixelApi.fetchPixelsByAdvertiser(this.advertiser.id);
      }

      this.dataLoader = false;
      return p;
    },
    async advertisersData() {
      this.advertisers = [];
      let accountId;
      if (isBlank(this.account)) {
        if (!isBlank(this.adreadyAccount)) {
          accountId = this.adreadyAccount.advisrGroupId;
        }
      } else {
        accountId = this.account.advisrGroupId;
      }
      if (!isBlank(accountId)) {
        const advertisersData = await advertiserApi.advertiserAll({
          accountId: accountId,
          type: ADVERTISER_TYPE,
        });
        advertisersData.forEach((adv) => {
          if (isBlank(adv.id)) {
            adv.id = adv.advisrAdvertiserId;
          }
          this.advertisers.push(adv);
        });
      }
    },
  },

  mounted() {
    Prism.highlightAll();
  },

  methods: {
    showPixelHelpModal() {
      this.$modal.show(PixelHelpModal, {}, { width: "600px" });
    },
    copyToClip() {
      window.navigator.clipboard.writeText(this.pixelScript);
      $("#copyBtn").html("Copied");
      setTimeout(() => {
        $("#copyBtn").html("Copy Pixel Code");
      }, 1000);
    },
    setPixelName(ev) {
      this.pixel.name = ev.target.value;
    },
    setSelected(val) {
      this.advertiser = val;
      if (val !== null) {
        this.pixel.advisrAdvertiserId = val.advisrAdvertiserId;
        this.pixel.advisrAdvertiserName = val.name;
      } else {
        this.pixel.advisrAdvertiserId = null;
      }
    },
    onSelectAccount(val) {
      this.account = val;
      this.advertiser = null;
    },
    createPixel(val) {
      this.pixel.type = val;
      let accountId;
      if (isBlank(this.account)) {
        accountId = this.adreadyAccount.id;
      } else {
        accountId = this.account.id;
      }
      this.pixel.adreadyAccountId = accountId;
      this.errorMsg = "";
      if (isBlank(this.pixel.advisrAdvertiserId)) {
        this.errorMsg = "Please select the Advertiser";
        return;
      }
      if (isBlank(this.pixel.name)) {
        this.errorMsg = "Please enter the pixel name";
        return;
      }
      this.loader = true;
      pixelApi
        .create(this.pixel)
        .then((pixel) => {
          this.loader = false;
          this.pixel = pixel;
          return pixel;
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.errorMsg =
            "Failed to Create Pixel. Try again or contact support";
        });
    },
  },
};
</script>
<style scoped>
.error {
  padding: 5px;
  font-weight: bold;
  color: red;
}
.pad-sm {
  padding: 5px 10px;
}
.pad-md {
  padding: 20px 10px;
}
.pad-md-1 {
  padding: 20px 10px 5px 10px;
}
.highlight {
  width: 95%;
  padding: 10px;
  overflow: auto;
  resize: none;

  /* Avoid pushing up the copy buttons. */
  background-color: #f7f7f7;
  border: none;
  border-radius: 5px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-right {
  text-align: right;
}
.a-inline {
  display: inline-block;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #666;
  }
  25% {
    stroke: #fdce07;
  }
  50% {
    stroke: #00abb7;
  }
  75% {
    stroke: #00abb7;
  }
  100% {
    stroke: #666;
  }
}

@keyframes colors {
  0% {
    stroke: #666;
  }
  25% {
    stroke: #fdce07;
  }
  50% {
    stroke: #00abb7;
  }
  75% {
    stroke: #00abb7;
  }
  100% {
    stroke: #666;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.load-row {
  padding-top: 5px;
}
.load-row svg {
  display: block;
  margin: auto;
}
.spin::before,
.spin::after {
  position: absolute;
  top: -2px;
  left: -2px;
  display: none;
  width: inherit;
  height: inherit;
  content: "";
  border: inherit;
  border-radius: inherit;
}
.spinner {
  margin-top: 20%;
  margin-left: 50%;
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .spin {
    width: 270px;
    height: 270px;
  }
  .image img {
    position: absolute;
    top: 19%;
    left: 21%;
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
<style lang="scss" scoped>
.a-inline,
.a-inline:visited {
  color: $secondary;
}
.a-inline:hover {
  text-decoration: underline;
}
</style>
