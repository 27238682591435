import { ApiCrud } from "adready-api/api/api-crud";
import http from "adready-api/api/http";

import { Pixel } from "../model/pixel";

const pixelApi = new ApiCrud(http.AXIOS, Pixel, "pixels");

pixelApi.add({
  fetchPixelsByAdvertiser: "GET /pixels/advertiser/:advertiserId",
});

export default pixelApi;
