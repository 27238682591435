import Base, { baseInit } from "adready-api/model/base";

export class Pixel extends Base {
  id = null; // Integer
  name = null; // String
  type = null; //CONVERSION or SEGMENT
  constructor(data) {
    super(data);
    baseInit(this, data, true);
  }
}
